<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>科室管理</el-breadcrumb-item>
			<el-breadcrumb-item>科室列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">

			<el-row :gutter="20">
        <el-col :span="4">
        	<el-button  type="success" @click="addKeshi.dialogVisible = true">添加科室</el-button>
        </el-col>
      </el-row>

			<el-table :data="listData" border style="width: 100%">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
				<el-table-column prop="id" label="编号" width="150">
				</el-table-column>
				<el-table-column prop="keshi_name" label="科室名称" width="180">
				</el-table-column>
				<el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeEnable(scope.row)"
              >
            </el-switch>
          </template>
				</el-table-column>
				</el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editKeshiInfo(scope.row.id)"
            ></el-button>
            <el-button type="primary" size="mini" icon="el-icon-delete" @click="delKeshi(scope.row.id)"></el-button>
          </template>

        </el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>


    <!-- 修改ems start -->
    <el-dialog
      title="修改"
      :visible.sync="editEms.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="editEms.emsForm" :rules="editEms.rules" ref="editEms.emsForm">
        <el-form-item label="科室名称">
          <el-input v-model="editEms.emsForm.keshi_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editEms.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editEmsInfoDo('editEms.emsForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改ems end -->

    <!-- 添加 -->
    <el-dialog
      title="添加科室"
      :visible.sync="addKeshi.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="addKeshi.addKeshiForm" :rules="addKeshi.rules" ref="addUser.addKeshiForm">
        <el-form-item label="科室名称">
          <el-input  v-model="addKeshi.addKeshiForm.keshi_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addKeshi.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addKeshiFunc('addKeshi.addKeshiForm')">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
export default {
  name: 'keshi',
  data () {
    return {
      listData: [],
      total: 100,
      pageSize: 10,
      currentPage4: 1,
      params: {
        keywords: '',
        page: 1,
        limit: 10
      },
      gridData: [],
      addKeshi: {
      	dialogVisible: false,
      	addKeshiForm: {}
      },
      editEms:{
        dialogVisible: false,
        emsForm:{}
      }
    }
  },
  created () {
    this.getArticleList()
  },
  methods: {
    async getArticleList () {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/keshi/keshi_list', {
          params: this.params
        }
      )
      this.listData = res.data.data
      this.total = res.data.total
      console.log(this.listData)
    }, // 每页显示多少条
    handleSizeChange (newSize) {
      console.log(newSize)
      this.params.limit = newSize
      this.getArticleList()
    }, // 第几页
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.params.page = newPage
      this.getArticleList()
    },
    //改变状态变成state
    async changeEnable(param) {
      console.log('state:',param)
      const {
      	data: res
      } = await this.$http.post(
          'api/admin/keshi/edit_status',
      		param
      )
      console.log('res:', res)
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
    },
    // 修改信息 获取数据
    async editKeshiInfo(id) {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/keshi/keshi_get', {
          params: {id: id}
        }
      )
      this.editEms.dialogVisible = true
      this.editEms.emsForm = res.data
    },
    // 修改ems信息 提交数据
    async editEmsInfoDo(id) {
        const {
					data: res
				} = await this.$http.post(
            'api/admin/keshi/keshi_do',
            this.editEms.emsForm
				)
        console.log('res结果：', res)
        if (res.status) return this.$message.error(res.message)

        this.$message.success(res.message)
        this.editEms.dialogVisible = false
        this.getArticleList()
    },
    // 添加科室
    async addKeshiFunc(formName) {
      let params = this.addKeshi.addKeshiForm
      if (!params.keshi_name) {
        return this.$message.error('科室名称不能为空')
      }
      console.log(this.addKeshi.addKeshiForm)
      const {
      	data: res
      } = await this.$http.post(
          'api/admin/keshi/add',
      		this.addKeshi.addKeshiForm
      )
      if (res.status) return this.$message.error(res.message)
      console.log('添加用户返回结果', res)
      this.$message.success(res.message)
      this.addKeshi.dialogVisible = false
      this.getArticleList()
      this.addKeshi.addKeshiForm = {}
    },
    // 删除
    async delKeshi(id) {
      const {
        data: res
      } = await this.$http.post(
        'api/admin/keshi/del',{
          'id':id
        }
      )
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getArticleList()
    }

  }
}
</script>
<style>
</style>
