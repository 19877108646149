import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

// 导入全局样式表
import './assets/css/global.css'

// 导入axios
import axios from 'axios'
// 配置请求的根路径
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = 'http://yanke.com/'
} else {
  axios.defaults.baseURL = 'https://api.pjrsykyy.cn/'
}
console.log('process.env.NODE_ENV', process.env.NODE_ENV)
// axios.defaults.baseURL = 'http://192.168.9.107/'
// axios请求拦截器添加token，保证拥有获取数据权限
axios.interceptors.request.use(config => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})
// 挂在到vue原型上
Vue.prototype.$http = axios

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
