<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区 -->
      <div class="avatar_box">
        <img src="../assets/logo.png"/>
      </div>
      <!-- 登录表单区 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRule"
        label-width="0px" class="login_form">
        <!-- 登录框 -->
        <el-form-item prop="username">
          <el-input
          v-model="loginForm.username"
          prefix-icon="el-icon-ice-cream-square"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="el-icon-lollipop"></el-input>
        </el-form-item>
        <!-- 提交按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 登录表单默认数据
      loginForm: {
        username: 'admin',
        password: ''
      },
      // 这是表单验证规则
      loginFormRule: {
        // 验证username
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度必须啊哟3-10之间', trigger: 'blur' }
        ],
        // 验证密码规则
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 11, message: '密码长度不够', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    resetLoginForm () {
      // 表单重置
      this.$refs.loginFormRef.resetFields()
    },
    login () {
      // 表单验证
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        // 发起向登录接口请求
        const { data: res } = await this.$http.post('api/admin/login', this.loginForm)
        console.log(res)
        if (res.status) return this.$message.error('登录失败')
        // 存储token
        window.sessionStorage.setItem('token', res.data.id)
        // 路由跳转
        console.log('路由跳转到home页面')
        this.$router.push('/home')
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .login_container{
    background-color: #2b4b6b;
    height: 100%;
  }
  .login_box{
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .avatar_box{
    height: 130px;
    width: 130px;
    border: 1px #eee solid;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
  .login_form{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .btns{
    display: flex;
    justify-content: flex-end;
  }
</style>
