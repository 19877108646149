<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>科室管理</el-breadcrumb-item>
			<el-breadcrumb-item>医生列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">

			<el-row :gutter="20">
        <el-col :span="4">
        	<el-button  type="success" @click="addYs('addYisheng.addYishengForm')">添加医生</el-button>
        </el-col>
      </el-row>

			<el-table :data="listData" border style="width: 100%">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
				<el-table-column prop="id" label="编号" width="150">
				</el-table-column>
				<el-table-column prop="yisheng_name" label="医生名称" width="180">
				</el-table-column>
        <el-table-column prop="keshi.keshi_name" label="科室" width="180">
				</el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="edityishengInfo(scope.row.id)"
            ></el-button>
            <el-button type="primary" size="mini" icon="el-icon-delete" @click="delyisheng(scope.row.id)"></el-button>
          </template>

        </el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>


    <!-- 修改ems start -->
    <el-dialog
      title="修改"
      :visible.sync="editEms.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="editEms.emsForm" :rules="editEms.rules" ref="editEms.emsForm">
        <el-form-item label="医生名称">
         <el-input  v-model="editEms.emsForm.yisheng_name"></el-input>
        </el-form-item>

        <el-form-item label="科室">
         <el-select v-model="editEms.emsForm.keshi_id" placeholder="请选择">
         <el-option
           v-for="item in keshi"
           :key="item.value"
           :label="item.keshi_name"
           :value="item.id">
         </el-option>
         </el-select>
        </el-form-item>

        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action="https://api.pjrsykyy.cn/api/admin/upload"
            name="pic"
            :show-file-list="false"
            :on-success="handleAvatarSuccessB"
            :before-upload="beforeAvatarUpload">
            <img v-if="editEms.emsForm.imageUrl" :src="editEms.emsForm.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="简介">
         <el-input
           type="textarea"
           placeholder="请输入内容"
           v-model="editEms.emsForm.jianjie"
           maxlength="1000"
           rows="10"
         ></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editEms.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editEmsInfoDo('editEms.emsForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改ems end -->

    <!-- 添加 -->
    <el-dialog
      title="添加医生"
      :visible.sync="addYisheng.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="addYisheng.addYishengForm" :rules="addYisheng.rules" ref="addYisheng.addYishengForm" >
        <el-form-item label="医生名称">
          <el-input  v-model="addYisheng.addYishengForm.yisheng_name"></el-input>
        </el-form-item>

        <el-form-item label="科室">
          <el-select v-model="addYisheng.addYishengForm.keshi_id" placeholder="请选择">
          <el-option
            v-for="item in keshi"
            :key="item.value"
            :label="item.keshi_name"
            :value="item.id">
          </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="头像">
        <el-upload
            class="avatar-uploader"
            action="https://api.pjrsykyy.cn/api/admin/upload"
            name="pic"
            :show-file-list="false"
            :on-success="handleAvatarSuccessA"
            :before-upload="beforeAvatarUpload">
            <img v-if="addYisheng.addYishengForm.imageUrl" :src="addYisheng.addYishengForm.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
<!--         <el-upload
            action="https://api.pjrsykyy.cn/api/admin/upload"
            name="pic"
            list-type="picture-card"
            :on-success="handleAvatarSuccessA"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview"
            >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog> -->
        </el-form-item>

        <el-form-item label="简介">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="addYisheng.addYishengForm.jianjie"
            maxlength="1000"
            rows="10"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addYisheng.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addYishengFunc('addYisheng.addYishengForm')">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
export default {
  name: 'yisheng',
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      listData: [],
      total: 100,
      pageSize: 10,
      currentPage4: 1,
      params: {
        keywords: '',
        page: 1,
        limit: 10
      },
      gridData: [],
      addYisheng: {
      	dialogVisible: false,
      	addYishengForm: {
          imageUrl:''
        }
      },
      editEms:{
        dialogVisible: false,
        emsForm:{}
      },
      keshi:[]
    }
  },
  created () {
    this.getArticleList()
    this.getKeshi()
  },
  methods: {
    async getArticleList () {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/yisheng/yisheng_list', {
          params: this.params
        }
      )
      this.listData = res.data.data
      this.total = res.data.total
      console.log(this.listData)
    }, // 每页显示多少条
    handleSizeChange (newSize) {
      console.log(newSize)
      this.params.limit = newSize
      this.getArticleList()
    }, // 第几页
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.params.page = newPage
      this.getArticleList()
    },
    //获取科室列表
    async getKeshi(param) {
      console.log('state:',param)
      const {
      	data: res
      } = await this.$http.get(
          'api/admin/keshi/keshi_list',{
            params:{type:1 }
          }

      )
      this.keshi = res.data
      console.log('res:', res)

    },
    // 修改信息 获取数据
    async edityishengInfo(id) {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/yisheng/yisheng_get', {
          params: {id: id}
        }
      )
      this.editEms.dialogVisible = true
      this.editEms.emsForm = res.data
    },
    // 修改ems信息 提交数据
    async editEmsInfoDo(id) {
        const {
					data: res
				} = await this.$http.post(
            'api/admin/yisheng/yisheng_do',
            this.editEms.emsForm
				)
        console.log('res结果：', res)
        if (res.status) return this.$message.error(res.message)

        this.$message.success(res.message)
        this.editEms.dialogVisible = false
        this.getArticleList()
    },
    // 添加用户
    async addYishengFunc(formName) {
      let params = this.addYisheng.addYishengForm
      if (!params.yisheng_name) {
        return this.$message.error('医生名称不能为空')
      }
      console.log(this.addYisheng.addYishengForm)
      const {
      	data: res
      } = await this.$http.post(
          'api/admin/yisheng/add',
      		this.addYisheng.addYishengForm
      )
      if (res.status) return this.$message.error(res.message)
      console.log('添加用户返回结果', res)
      this.$message.success(res.message)
      this.$refs[formName].resetFields();
      this.addYisheng.dialogVisible = false
      this.addYisheng.addYishengForm = {}
      this.getArticleList()

    },
    // 删除
    async delyisheng(id) {
      const {
        data: res
      } = await this.$http.post(
        'api/admin/yisheng/del',{
          'id':id
        }
      )
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getArticleList()
    },
    addYs(formName){
      console.log('formName',formName)
      this.addYisheng.dialogVisible = true
      // this.$refs[formName].resetFields();
    },
    handleAvatarSuccessA(res, file) {

      //this.addYisheng.addYishengForm.imageUrl_raw = URL.createObjectURL(file.raw);
      // addYishengFormthis.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = res.data;
      console.log('file', file)
      console.log('res', res)
      // this.addYisheng.addYishengForm.imageUrl = res.data;
      this.addYisheng.addYishengForm.imageUrl = res.data;
      console.log('this.addYisheng.addYishengForm.imageUrl', this.addYisheng.addYishengForm.imageUrl)
    },
    handleAvatarSuccessB(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = res.data;
      console.log('file', file)
      console.log('res', res)
      this.editEms.emsForm.imageUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isJPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isJPNG) {
        // this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {
      console.log('file', file);
      console.log('handleRemove', fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>
<style>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
</style>
