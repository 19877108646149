<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>内容管理</el-breadcrumb-item>
			<el-breadcrumb-item>添加内容</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">


      <el-form
      style="border: 0px red solid"
      label-width="70px"
      :model="addNews.newsForm"
      :rules="addNews.rules"
      ref="addNews.newsForm">
        <el-form-item label="标题">
          <el-input v-model="addNews.newsForm.title"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            v-model="addNews.newsForm.category_id"
            filterable
            allow-create
            default-first-option
            clearable
            placeholder="请选分类">
            <el-option
              v-for="item in category"
              :key="item.id"
              :label="item.category_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" style="height: 560px;">

          <WangEditor @sendWangEditor="sendWangEditor" ref="wangEditor"></WangEditor>

        </el-form-item>

        <el-form-item label="" style="text-align: center;">
          <el-button @click="resetForm('addNews.newsForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('addNews.newsForm')">确 定</el-button>
        </el-form-item>

      </el-form>

    </el-card>

	</div>
</template>

<script>
    import WangEditor from '../WangEditor.vue'
export default {
  name: 'ArticleList',
  components: {
        WangEditor
      },
  data () {
    return {
      category:[],
      addNews:{
        dialogVisible: false,
        newsForm:{}
      }
    }
  },
  created () {
    this.getCategory()
  },
  methods: {
    sendWangEditor(val){
      console.log('富文本内容', val)
      this.addNews.newsForm.content = val
    },
    async getCategory(){
      const {
        data: res
      } = await this.$http.get(
        'api/admin/news_category/get_list'
      )
      this.category = res.data
    },
    // 修改ems信息 获取数据
    async addNewsDo(id) {
      console.log('from', id)
      console.log('addNewsDo', this.addNewsDo.newsForm)
      // const {
      //   data: res
      // } = await this.$http.get(
      //   'api/admin/news/add', {
      //     params: {id: id}
      //   }
      // )
      // this.editEms.dialogVisible = true
      // this.editEms.emsForm = res.data
    },
    async submitForm(formName) {
      let params = this.addNews.newsForm
      if (!params.title) {
        return this.$message.error('内容标题不能为空')
      }

      if (!params.content) {
        return this.$message.error('内容不能为空')
      }
      console.log('addNews.newsForm', this.addNews.newsForm)

      const {
        data: res
      } = await this.$http.post(
        'api/admin/news/add',
        params
      )
      console.log('res结果：', res)
      if (res.status) return this.$message.error(res.message)
      this.$router.push('/news')
    },
    resetForm(formName) {
      this.addNews.newsForm.title = ''
      this.addNews.newsForm.content = ''
      this.$refs.wangEditor.editor.clear();
    }


  }
}
</script>
<style>
</style>
