<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>内容管理</el-breadcrumb-item>
			<el-breadcrumb-item>编辑内容</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">


      <el-form
      style="border: 0px red solid"
      label-width="70px"
      :model="editNewsForm"
      ref="editNewsForm">
        <el-form-item label="标题">
          <el-input v-model="editNewsForm.title"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            v-model="editNewsForm.category_id"
            filterable
            allow-create
            default-first-option
            clearable
            placeholder="请选分类">
            <el-option
              v-for="item in category"
              :key="item.id"
              :label="item.category_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" style="height: 560px;">

          <WangEditor @sendWangEditor="sendWangEditor" ref="wangEditor"></WangEditor>

        </el-form-item>

        <el-form-item label="" style="text-align: center;">
          <el-button @click="resetForm('editNewsForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('editNewsForm')">确 定</el-button>
        </el-form-item>

      </el-form>

    </el-card>

	</div>
</template>

<script>
    import WangEditor from '../WangEditor.vue'
export default {
  name: 'ArticleList',
  components: {
        WangEditor
      },
  data () {
    return {
      setWangEditorContent: '',
      editNewsForm:{},
      id:0,
      category:[]
    }
  },
  created (options) {

    console.log('aaaaaaa',this.$route.query.id)
    this.editNewsForm.id = this.$route.query.id
    this.getNew(this.$route.query.id)
    this.getCategory()
  },
  mounted() {
    console.log('this.$refs---------------', this.$refs)
    console.log('this.editNewsForm.content---------------', this.editNewsForm.content)
    this.$nextTick(()=>{
      this.$refs.wangEditor.html = this.editNewsForm.content
    })

  },
  methods: {
    sendWangEditor(val){
      console.log('富文本内容', val)
      this.editNewsForm.content = val
    },
    async getNew(id) {
      console.log('id:', this.id)
      const {
        data: res
      } = await this.$http.get(
        'api/admin/news/get', {
          params: {
            id: id,
          }
        }
      )
      console.log('res', res)
      this.editNewsForm = res.data
      this.$refs.wangEditor.html = this.editNewsForm.content
      console.log('this.editNewsForm', this.editNewsForm)

    },
    async getCategory(){
      const {
        data: res
      } = await this.$http.get(
        'api/admin/news_category/get_list'
      )
      this.category = res.data
    },
    async submitForm(formName) {
      let params = this.editNewsForm
      if (!params.title) {
        return this.$message.error('内容标题不能为空')
      }

      if (!params.content) {
        return this.$message.error('内容不能为空')
      }
      console.log('editNewsForm', this.editNewsForm)

      const {
        data: res
      } = await this.$http.post(
        'api/admin/news/edit',
        params
      )
      console.log('res结果：', res)
      if (res.status) return this.$message.error(res.message)
      this.$router.push('/news')
    },
    resetForm(formName) {
      this.editNewsForm.title = ''
      this.editNewsForm.content = ''
      this.$refs.wangEditor.editor.clear();

    }


  }
}
</script>
<style>
</style>
