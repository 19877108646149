<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <div>
            <el-statistic
              group-separator=","
              title="昨天包裹数量"
            >
              <template slot="formatter">
                {{ems_info.zts}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="今天包裹数量">
              <template slot="formatter">
                {{ems_info.jts}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="当月包裹数量">
              <template slot="formatter">
                {{ems_info.dys}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="上个月包裹数量">
              <template slot="formatter">
                {{ems_info.sys}}
              </template>
            </el-statistic>
          </div>
        </el-col>
      </el-row>
      <el-divider content-position="center"><i class="el-icon-s-data"></i></el-divider>
      <el-row :gutter="20">
        <el-col :span="6">
          <div>
            <el-statistic
              group-separator=","
              title="昨天邮费"
            >
              <template slot="formatter">
                {{ems_info.ztfy}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="今天邮费">
              <template slot="formatter">
                {{ems_info.jtfy}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="当月邮费">
              <template slot="formatter">
                {{ems_info.dyfy}}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="上个月邮费">
              <template slot="formatter">
                {{ems_info.syfy}}
              </template>
            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </el-card>


    <el-card class="box-card index-margin-top">
      <el-divider content-position="left">用户使用金额</el-divider>
      <el-table
        :data="userTotalDataArray"
        height="450"
        border
        style="width: 100%">
        <el-table-column
          prop="username"
          label="姓名"
          width="100">
        </el-table-column>

        <el-table-column
          width="100"
          prop="zong"
          label="总充值金额">
        </el-table-column>
        <el-table-column
          prop="shengyu"
          label="剩余金额"
          width="100">
          <template slot-scope="scope">
            <el-tag size="medium" type="danger">{{ scope.row.shengyu }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="zts"
          label="昨天包裹数"
          width="90">
        </el-table-column>
        <el-table-column
          prop="jts"
          label="今天包裹数"
          width="90">
        </el-table-column>
        <el-table-column
          prop="dys"
          label="当月裹数"
          width="100">
        </el-table-column>
        <el-table-column
          prop="ztfy"
          label="昨天费用"
          width="100">
        </el-table-column>
        <el-table-column
          prop="jtfy"
          label="今天费用"
          width="100">
        </el-table-column>
        <el-table-column
          prop="dyfy"
          label="当月费用"
          >
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      like: true,
      value1: 4154.564,
      value2: 1314,
      title: '昨天包裹数量',
      tagValue: '',
      user_info: {

      },
      ems_info: {},
      userTotalDataArray: []
    }
  },
  created () {
    window.sessionStorage.setItem('activePath', '/index')
    //this.getUserInfo()
//this.userTotalData()
  },
  methods: {
    /**
     * 用户统计数据列表
     */
    async userTotalData () {
      const {
        	data: res
      } = await this.$http.get(
        	'api/admin/article/user_total_data'
      )
      console.log(res.data)
      this.userTotalDataArray = res.data
      // if (this.user_info.sy_money <= 0) this.tagValue = 'danger'
      // this.ems_info = res.data.ems_total
    },
    async getUserInfo () {
      const {
        	data: res
      } = await this.$http.get(
        	'api/admin/article/total'
      )
      console.log(res)
      this.user_info = res.data.user_total
      if (this.user_info.sy_money <= 0) this.tagValue = 'danger'
      this.ems_info = res.data.ems_total
    }
  }
}
</script>
<style lang="less">
  .like {
    cursor: pointer;
    font-size: 25px;
    display: inline-block;
  }
  .index-margin-top{
    margin-top: 20px;
  }
</style>
