<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>挂号管理</el-breadcrumb-item>
			<el-breadcrumb-item>添加报告</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">


      <el-form
      style="border: 0px red solid"
      label-width="70px"
      :model="addNews.newsForm"
      :rules="addNews.rules"
      ref="addNews.newsForm">
        <el-form-item label="标题">
          <el-input v-model="addNews.newsForm.title"></el-input>
        </el-form-item>
        <el-form-item label="报告人">



              <el-col :span="4">
                <el-select
                  @change="goodsInfo"
                  @clear="clearGoods"
                  v-model="addNews.newsForm.jiuzhenren_id"
                  filterable
                  allow-create
                  default-first-option
                  clearable
                  placeholder="请选报告人">
                  <el-option
                    v-for="item in jiuzhenren"
                    :key="item.id"
                    :label="item.jiuzhenren_name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="20">
                <div class="jiuzhenren_style" :style="jiuzhenren_show">
                  年龄：<span>{{goodsShow.age}}</span>
                  性别：<span>{{goodsShow.sex}}</span>
                  手机：<span>{{goodsShow.phone}}</span>
                  身份证：<span>{{goodsShow.idcard}}</span>
                  地址：<span>{{goodsShow.address}}</span>
                </div>

              </el-col>

        </el-form-item>
        <el-form-item label="内容" style="height: 560px;">

          <WangEditor @sendWangEditor="sendWangEditor" ref="wangEditor"></WangEditor>

        </el-form-item>

        <el-form-item label="" style="text-align: center;">
          <el-button @click="resetForm('addNews.newsForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('addNews.newsForm')">确 定</el-button>
        </el-form-item>

      </el-form>

    </el-card>

	</div>
</template>

<script>
    import WangEditor from '../WangEditor.vue'
export default {
  name: 'ArticleList',
  components: {
        WangEditor
      },
  data () {
    return {
      addNews:{
        dialogVisible: false,
        newsForm:{}
      },
      jiuzhenren:[],
      goodsShow:{},
      jiuzhenren_show:"display:none"
    }
  },
  created () {
    this.getJiuzhenren()
  },
  methods: {
    sendWangEditor(val){
      console.log('富文本内容', val)
      this.addNews.newsForm.content = val
    },
    // 修改ems信息 获取数据
    async getJiuzhenren() {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/get_jiuzhenren'
      )
      this.jiuzhenren = res.data
    },
    async submitForm(formName) {
      let params = this.addNews.newsForm
      if (!params.title) {
        return this.$message.error('内容标题不能为空')
      }

      if (!params.content) {
        return this.$message.error('内容不能为空')
      }
      console.log('addNews.newsForm', this.addNews.newsForm)

      const {
        data: res
      } = await this.$http.post(
        'api/admin/baogao/add',
        params
      )
      console.log('res结果：', res)
      if (res.status) return this.$message.error(res.message)
      this.$router.push('/baogao')
    },
    resetForm(formName) {
      this.addNews.newsForm.title = ''
      this.addNews.newsForm.content = ''
      this.$refs.wangEditor.editor.clear();
    },
    jiuzhenrenFunc(){
       // this.addNews.newsForm.jiuzhenren_id =
    },
    goodsInfo(){
      console.log('input 事件')
      var goods_id = this.addNews.newsForm.jiuzhenren_id
      var goodsShow = ''
      this.jiuzhenren.forEach(function(item,index){
        if(item.id == goods_id){
          goodsShow = item
          console.log('item',item)
        }
      })
      this.goodsShow = goodsShow
      this.addNews.newsForm.jiuzhenren_user_id = goodsShow.user_id
      this.jiuzhenren_show = 'display:block'

    },
    clearGoods(){
      this.jiuzhenren_show = 'display:none'
    },

  }
}
</script>
<style>

  jiuzhenren_style{
    color: #C0C4CC;
  }
  .jiuzhenren_style span{
    color: darkred;
    margin-right: 15px;
  }

</style>
