  <template>
  <el-container>
    <!-- 头部区域 -->
    <el-header>
			<div class="header_left">
				<h3>眼科管理系统</h3>
			</div>
			<div class="header_right">
				<el-button type="info" @click="logout">退出</el-button>
			</div>
    </el-header>
    <el-container>
      <!-- 左侧 -->
      <el-aside width="200px">
				<el-menu
          :default-active="activePath"
          :default-openeds="['2','3','4']"
          class="el-menu-vertical-demo"
					background-color="#2b4b6b"
          text-color="#fff"
          active-text-color="#ffd04b"
          :router="true"
        >


          <!-- 一级菜单 -->
					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-s-cooperation"></i>
							<span>预约挂号</span>
						</template>
						<!-- 二级级菜单 -->
						<el-menu-item
              index="/guahao"
              @click="saveNavState('/guahao')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>预约列表</span>
							</template>
						</el-menu-item>
						<el-menu-item
              index="/add_baogao"
              @click="saveNavState('/add_baogao')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>添加报告</span>
							</template>
						</el-menu-item>
						<el-menu-item
              index="/baogao"
              @click="saveNavState('/baogao')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>报告列表</span>
							</template>
						</el-menu-item>
						<el-menu-item
              index="/jiuzhenren"
              @click="saveNavState('/jiuzhenren')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>就诊人列表</span>
							</template>
						</el-menu-item>

          </el-submenu>

          <!-- 一级菜单 -->
					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-s-cooperation"></i>
							<span>科室管理</span>
						</template>
						<!-- 二级级菜单 -->
						<el-menu-item
              index="/keshi"
              @click="saveNavState('/keshi')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>科室列表</span>
							</template>
						</el-menu-item>
						<!-- 二级级菜单 -->
						<el-menu-item
              index="/yisheng"
              @click="saveNavState('/yisheng')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>医生列表</span>
							</template>
						</el-menu-item>
					</el-submenu>


          <!-- 一级菜单 -->
					<el-submenu index="4">
						<template slot="title">
							<i class="el-icon-s-cooperation"></i>
							<span>内容管理</span>
						</template>
						<!-- 二级级菜单 -->
						<el-menu-item
              index="/add_news"
              @click="saveNavState('/add_news')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>内容添加</span>
							</template>
						</el-menu-item>
						<el-menu-item
              index="/news"
              @click="saveNavState('/news')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>内容列表</span>
							</template>
						</el-menu-item>
						<el-menu-item
              index="/category_news"
              @click="saveNavState('/category_news')"
            >
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>分类管理</span>
							</template>
						</el-menu-item>

					</el-submenu>

				</el-menu>
      </el-aside>
      <!-- 右侧主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      // 被激活链接地址
      activePath: '/guahao',
      menuShow: 'display:none'
    }
  },
  created () {
    let currentPath = window.sessionStorage.getItem('activePath')
    if(currentPath){
      this.activePath = window.sessionStorage.getItem('activePath')
    }

    if (window.sessionStorage.getItem('token') == 1) {
        this.menuShow = 'display:block'
    }
  },
  methods: {
    logout () {
      // 清楚sessionStorage里面的token
      window.sessionStorage.clear()
      // 重定向，跳转到登录页面
      this.$router.push('/login')
    },
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
	.el-container {
		height: 100%;
	}

	.el-header {
		height: 120px;
		background-color: #2B4B6B;
		align-items: center;
		justify-content: space-between;
		display: flex;
		color: #fff;
		/* margin-bottom: 1px ; */
	}

	.el-aside {
    width: 200px;
		background-color: #2B4B6B;
	}

	.el-main {
		background-color: #EAEDF1;
	}
</style>
