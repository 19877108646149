<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>系统设置</el-breadcrumb-item>
			<el-breadcrumb-item>用户列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<el-row :gutter="20">
				<el-col>
					<el-button size="mini" type="success" @click="addUser.dialogVisible = true">添加用户</el-button>
        	<el-button size="mini" @click="rechangeLogFunc" >查看充值记录</el-button>
        </el-col>
			</el-row>
      <!-- 用户列表 -->
			<el-table :data="listData" border style="width: 100%">
				<el-table-column prop="id" label="编号" width="150">
				</el-table-column>
				<el-table-column prop="username" label="名称" width="100">
				</el-table-column>
        <el-table-column prop="phone" label="手机" width="180">
        </el-table-column>
				<el-table-column prop="account" label="充值金额">
				</el-table-column>
        <el-table-column prop="syje" label="余额">
        </el-table-column>
				<el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-value="0"
              inactive-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeEnable(scope.row)"
              >
            </el-switch>
          </template>
				</el-table-column>
				<el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editUserInfo(scope.row.id)"
            ></el-button>
            <!-- 删除 -->
            <el-button type="primary" size="mini" icon="el-icon-delete" @click="delUser(scope.row.id)"></el-button>
          </template>

				</el-table-column>
			</el-table>
      <!-- 用户列表分页 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>

    <!-- 充值记录 -->
    <el-dialog
      title="充值记录"
      :visible.sync="rechangeLog"
      width="50%"
      >
      <el-table
        :data="rechangetableData"
        style="width: 100%">
        <el-table-column
          prop="created_at"
          label="日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="user.username"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="add_money"
          label="金额">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="rechangeHandleSizeChange" @current-change="rechangeHandleCurrentChange"
      	:current-page="rechangetablePage.currentPage4" :page-sizes="[5]" :page-size="rechangetablePage.pageSize"
      	layout="total, sizes, prev, pager, next, jumper" :total="rechangetablePage.total">
      </el-pagination>
    </el-dialog>
    <!-- 充值记录编辑对话框 -->
    <el-dialog
      title="编辑信息"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <!-- 主题内容 -->
      <el-form label-width="70px" :model="editUserData">
        <el-form-item label="用户名">
          <el-input v-model="editUserData.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="editUserData.account" disabled></el-input>
        </el-form-item>
        <el-form-item label="充值金额">
          <el-input v-model="editUserData.addAccount" ></el-input>
        </el-form-item>
        <!-- 底部按钮 -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfoDo(editUserData.id)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加用户 -->
    <el-dialog
      title="添加用户"
      :visible.sync="addUser.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="addUser.addUserForm" :rules="addUser.rules" ref="addUser.addUserForm">
        <el-form-item label="用户名">
          <el-input  v-model="addUser.addUserForm.username"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="addUser.addUserForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUser.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserFunc('addUser.addUserForm')">确 定</el-button>
      </span>
    </el-dialog>


	</div>
</template>

<script>
	export default {
		name: 'UserList',
		data() {
			return {
        addUser: {
          dialogVisible: false,
          addUserForm: {
            username: ''
          },
          rules: {
            username: [
              { required: true, message: '请输入活动名称', trigger: 'blur' },
              { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
            ]
          }
        },
        rechangetableData:[],
        rechangetablePage: {
          total:100,
          pageSize:5,
          currentPage4:1,
          params:{
          	page: 1,
          	limit: 5
          }
        },
        addAccount: '',
        editUserData:{},
        status: true,
        dialogVisible: false,
        rechangeLog: false,
				listData: [],
				total:100,
				pageSize:10,
				currentPage4:1,
				params:{
					page: 1,
					limit: 10
				}
			}
		},
		created() {
			this.getArticleList()
		},
		methods: {
			async getArticleList() {
				const {
					data: res
				} = await this.$http.get(
					'api/admin/user/list', {
						params: this.params
					}
				)
				this.listData = res.data.data
				this.total = res.data.total
				console.log(this.listData)
			}, //每页显示多少条
			handleSizeChange(newSize){
				console.log(newSize)
				this.params.limit = newSize
				this.getArticleList()
			},//第几页
			handleCurrentChange(newPage){
				console.log(newPage)
				this.params.page = newPage
				this.getArticleList()
			},
			async editUserInfo(id) {
				const {
					data: res
				} = await this.$http.get(
					'api/admin/user/user_info', {
						params: {user_id: id}
					}
				)
        this.dialogVisible = true
        this.editUserData = res.data
      },
      //编辑
      async editUserInfoDo(id) {
        const {
					data: res
				} = await this.$http.post(
            'api/admin/user/user_info_do',
            this.editUserData
				)
        console.log('res结果：', res)
        if (res.status) return this.$message.error(res.message)

        this.$message.success(res.message)
        this.dialogVisible = false
        this.getArticleList()
      },
      //充值记录列表
      async rechangeLogFunc() {
        this.rechangeLog = true
        const {
					data: res
				} = await this.$http.get(
            'api/admin/user/get_rechange_log',{
						params: this.rechangetablePage.params
					}
				)
        console.log('充值记录', res)
        this.rechangetableData = res.data.data
        this.rechangetablePage.total = res.data.total
        console.log(this.listData)
      },
      //充值记录分页，每页显示多少条
      rechangeHandleSizeChange(newSize) {
				console.log(newSize)
				this.rechangetablePage.params.limit = newSize
				this.rechangeLogFunc()
      },
      //充值记录分页，第几页
      rechangeHandleCurrentChange(newPage){
      	console.log(newPage)
      	this.rechangetablePage.params.page = newPage
      	this.rechangeLogFunc()
      },
      //改变状态变成state
      async changeEnable(param) {
        console.log('state:',param)
        const {
        	data: res
        } = await this.$http.post(
            'api/admin/user/change_enable_user',
        		param
        )
        console.log('res:', res)
        if (res.status) return this.$message.error(res.message)
        this.$message.success(res.message)
      },
      // 添加用户
      async addUserFunc(formName) {
        let params = this.addUser.addUserForm
        if (!params.username) {
          return this.$message.error('用户名不能为空')
        }
        if (!params.phone) {
          return this.$message.error('手机号不能为空')
        }
        console.log(this.addUser.addUserForm)
        const {
        	data: res
        } = await this.$http.post(
            'api/admin/user/add_user',
        		this.addUser.addUserForm
        )
        if (res.status) return this.$message.error(res.message)
        console.log('添加用户返回结果', res)
        this.$message.success(res.message)
        this.addUser.dialogVisible = false
        this.getArticleList()
      },
      // 删除用户
      async delUser(id) {
        const {
          data: res
        } = await this.$http.post(
          'api/admin/user/del_user',{
            'id':id
          }
        )
        if (res.status) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getArticleList()
      }

		}
	}
</script>
<style>
</style>
