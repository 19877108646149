import Vue from 'vue'
import {
  Button,
  Form,
  FormItem,
  Input,
  Message,
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  DatePicker,
  Descriptions,
  DescriptionsItem,
  Link,
  Dialog
} from 'element-ui'

Vue.use(
  Button,
  Form,
  FormItem,
  Input,
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  DatePicker,
  Descriptions,
  DescriptionsItem,
  Link,
  Dialog
)
// 全局挂载在vue上能全局使用, 消息提示
Vue.prototype.$message = Message
