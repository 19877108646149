<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>内容管理</el-breadcrumb-item>
			<el-breadcrumb-item>分类列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
      <el-row :gutter="20">
      	<el-col>
      		<el-button size="mini" type="success" @click="add.dialogVisible = true">添加分类</el-button>
        </el-col>
      </el-row>
			<el-table :data="listData" border style="width: 100%">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
				<el-table-column prop="id" label="编号" width="150">
				</el-table-column>
				<el-table-column prop="category_name" label="分类名称" width="180">
				</el-table-column>
				<el-table-column prop="created_at" label="时间">
				</el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editData(scope.row.id)"
            ></el-button>
            <!-- 删除 -->
            <el-button
            type="primary"
            size="mini"
            icon="el-icon-delete"
            @click="delData(scope.row.id)"></el-button>
          </template>

        </el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加分类"
      :visible.sync="add.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="add.dataForm" :rules="add.rules" ref="add.dataForm">
        <el-form-item label="分类名称">
          <el-input  v-model="add.dataForm.category_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDataFunc('add.dataForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑分类"
      :visible.sync="edit.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="edit.dataForm" :rules="edit.rules" ref="edit.dataForm">
        <el-form-item label="分类名称">
          <el-input  v-model="edit.dataForm.category_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDataFunc('edit.dataForm')">确 定</el-button>
      </span>
    </el-dialog>

	</div>
</template>

<script>
export default {
  name: 'ArticleList',
  data () {
    return {
      listData: [],
      total: 100,
      pageSize: 10,
      currentPage4: 1,
      params: {
        search_time: '',
        keywords: '',
        page: 1,
        limit: 10
      },
      add:{
        dialogVisible: false,
        dataForm:{}
      },
      edit:{
        dialogVisible: false,
        dataForm:{}
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/news_category/list',{
          params: this.params
        }
      )
      this.listData = res.data.data
      this.total = res.data.total
      console.log(this.listData)
    }, // 每页显示多少条
    handleSizeChange (newSize) {
      console.log(newSize)
      this.params.limit = newSize
      this.getList()
    }, // 第几页
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.params.page = newPage
      this.getList()
    },
    // 添加分类
    async addDataFunc(){
      let params = this.add.dataForm
      if (!params.category_name) {
        return this.$message.error('分类名称不能为空')
      }
      const {
      	data: res
      } = await this.$http.post(
          'api/admin/news_category/add',
      		this.add.dataForm
      )
      if (res.status) return this.$message.error(res.message)
      console.log('添加返回结果', res)
      this.$message.success(res.message)
      this.add.dialogVisible = false
      this.getList()
      this.add.dataForm = {}
    },
    // 获取单条数据
    async editData(id) {
      console.log('id:', this.id)
      const {
        data: res
      } = await this.$http.get(
        'api/admin/news_category/get', {
          params: {
            id: id,
          }
        }
      )
      this.edit.dialogVisible = true
      console.log('res', res)
      this.edit.dataForm = res.data
      console.log('this.editDataForm', this.edit.dataForm)
    },
    // 编辑
    async editDataFunc(id) {
      const {
        data: res
      } = await this.$http.post(
          'api/admin/news_category/edit',
          this.edit.dataForm
      )
      console.log('res结果：', res)
      if (res.status) return this.$message.error(res.message)

      this.$message.success(res.message)
      this.edit.dialogVisible = false
      this.getList()
    },
    // 删除
    async delData(id) {
      const {
        data: res
      } = await this.$http.post(
        'api/admin/news_category/del',{
          'id':id
        }
      )
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getList()
    }

  }
}
</script>
<style>
</style>
